import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import {
  //VideoCard,
  //GuidelinesCard,
  //Testimonials,
  JoinUs,
  StoryCard,
  //SegmentList,
} from '../components';
/*
const mockContent = [
  {
    id: 0,
    name: 'Fulano de Tal 1',
    text:
      'O maior benefício são os descontos e a segurança de saber que são os melhores profissionais do mercado. Eu ficava um pouco receosa sobre os descontos, mas vi que são reais.',
  },
  {
    id: 1,
    name: 'Fulano de Tal 2',
    text:
      'O maior benefício são os descontos e a segurança de saber que são os melhores profissionais do mercado. Eu ficava um pouco receosa sobre os descontos, mas vi que são reais.',
  },
  {
    id: 2,
    name: 'Fulano de Tal 3',
    text:
      'O maior benefício são os descontos e a segurança de saber que são os melhores profissionais do mercado. Eu ficava um pouco receosa sobre os descontos, mas vi que são reais.',
  },
  {
    id: 3,
    name: 'Fulano de Tal 4',
    text:
      'O maior benefício são os descontos e a segurança de saber que são os melhores profissionais do mercado. Eu ficava um pouco receosa sobre os descontos, mas vi que são reais.',
  },
];

// mockup data
const data = [
  {
    id: 0,
    title: 'Fase Inicial',
    items: [
      {
        id: 0,
        title: 'ALIANÇAS',
        icon: '',
      },
      {
        id: 1,
        title: 'BUFFET',
        icon: '',
      },
      {
        id: 2,
        title: 'CELEBRANTE',
        icon: '',
      },
      {
        id: 3,
        title: 'FOTOGRAFIA',
        icon: '',
      },
      {
        id: 4,
        title: 'ASSESSORIA & CERIMONIAL',
        icon: '',
      },
      {
        id: 5,
        title: 'FILMAGEM',
        icon: '',
      },
      {
        id: 6,
        title: 'ESPAÇO',
        icon: '',
      },
      {
        id: 7,
        title: 'MÚSICA PARA CERIMÔNIA',
        icon: '',
      },
      {
        id: 8,
        title: 'BANDA',
        icon: '',
      },
      {
        id: 9,
        title: 'DJ & BOATE',
        icon: '',
      },
      {
        id: 10,
        title: 'DECORAÇÃO',
        icon: '',
      },
    ],
  },
  {
    id: 1,
    title: 'Fase Intermediária',
    items: [],
  },
  {
    id: 2,
    title: 'Fase Final',
    items: [],
  },
];
*/
const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
`;

export const AboutPage = () => {
  return (
    <>
      <Wrapper>
        <StoryCard />
        <hr />
      </Wrapper>
      <Wrapper>
        <StyledContainer>
          <JoinUs />
        </StyledContainer>
      </Wrapper>
    </>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
